<template>
	<div>
		<ITCSpinner :loaded="loaded && data != null">
			<b-table :fields="fields" :items="data.data" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" v-if="data != null" fixed>
				<template #cell(status)="data">
					<div
						:class="['w-100', 'device-status-' + data.value.status, 'status']"
						v-b-tooltip="data.value.status_change + ': ' + data.value.status_data"
					>
						{{ data.value.status }}
					</div>
				</template>
				<template #cell(server)="item">
					<span v-if="data.servers[item.value]">
						{{ data.servers[item.value] }}
					</span>
				</template>
				<template #cell(TeleportId)="item">
					<b-link
						v-if="item.item.server != null && data.teleports[item.item.server][item.value]"
						:href="'/noc/app/teleport/' + item.item.server + '/' + item.item.TeleportId"
						>{{ data.teleports[item.item.server][item.value] }}</b-link
					>
				</template>
				<template #cell(NetworkId)="item">
					<b-link
						v-if="item.item.server != null && item.item.NetworkId != 0 && data.networks[item.item.server][item.value]"
						:href="'/noc/app/network/' + item.item.server + '/' + item.item.NetworkId"
						>{{ data.networks[item.item.server][item.value] }}</b-link
					>
				</template>
				<template #cell(InrouteGroupId)="item">
					<b-link
						v-if="item.item.server != null && item.item.InrouteGroupId != 0 && data.inroutes[item.item.server][item.value]"
						:href="'/noc/app/inroute/' + item.item.server + '/' + item.item.InrouteGroupId"
						>{{ data.inroutes[item.item.server][item.value] }}</b-link
					>
					<span v-if="item.value == 0"> ------ </span>
				</template>
				<template #cell(alert_time)="data">
					{{ data.value !== '0000-00-00 00:00:00' ? data.value : '' }}
				</template>
				<template #cell(last_alert_sent)="data">
					{{ data.value !== '0000-00-00 00:00:00' ? data.value : '' }}
				</template>
				<template #cell(muted)="data">
					<div v-if="data.value == 1">
						<div>
							Muted by {{ data.item.mutename }} on {{ data.item.mutedate }} (<span class="clickable" @click="showMuteModal(data.item)"> Unmute </span
							>)
						</div>
						<div>
							<strong
								>Muted Until:
								{{ data.item.muteuntil == '0000-00-00 00:00:00' ? 'Forever' : data.item.muteuntil }}
								- reason: {{ data.item.mutereason }}</strong
							>
						</div>
					</div>
					<div v-else>
						<span class="clickable" @click.prevent="showMuteModal(data.item)">Click to Mute</span>
					</div>
				</template>
			</b-table>
		</ITCSpinner>
		<MuteModal :alert="selectedAlert" @hidden="selectedAlert = null" @saved="updateData" />
	</div>
</template>
<script>
export default {
	name: 'NOCAlertsLinecards',
	components: {
		ITCSpinner: () => import('@/view/content/loaders/itcSpinner.vue'),
		MuteModal: () => import('@/view/content/widgets/admin/NOCAlertsLinecardModal.vue'),
	},
	data() {
		return {
			loaded: false,
			sortBy: 'alert_time',
			sortDesc: true,
			fields: [
				{ key: 'NetModemName', label: 'Name' },
				{ key: 'status', label: 'Status' },
				{ key: 'server', label: 'Server', sortable: true },
				{ key: 'TeleportId', label: 'Teleport', sortable: true },
				{ key: 'NetworkId', label: 'Network', sortable: true },
				{ key: 'InrouteGroupId', label: 'Inroute', sortable: true },
				{ key: 'alert_wait' },
				{ key: 'alert_again_wait', label: 'Additional Alert' },

				{ key: 'alert_time', sortable: true },
				{ key: 'last_alert_sent', sortable: true },
				{ key: 'muted' },
			],
			data: null,
			selectedAlert: null,
		};
	},
	methods: {
		async load() {
			this.loaded = false;
			let { data } = await this.$http.get('nocalertslinecards');
			this.data = data.data;
			this.loaded = true;
		},
		showMuteModal(alert) {
			this.selectedAlert = alert;
			this.$bvModal.show('muteModal');
		},
		updateData() {
			console.log('update');
			this.load();
		},
	},
	async mounted() {
		await this.load();
		if (this.$route.params.alertid && this.$route.params.alertid.startsWith('l')) {
			var alert = this.data.data.find(f => f.id == this.$route.params.alertid.substring(1));
			if (alert) {
				this.showMuteModal(alert);
			}
		}
	},
};
</script>
<style scoped lang="scss">
.status {
	text-align: center;
	padding: 2px 5px;
	border-radius: 5px;
}
.device-status-OK {
	background: $success-light;
	color: $success;
}
.device-status-ALARM {
	background: #f7bbc0;
	color: $danger;
}
.device-status-DEACTIVATED {
	background: rgb(190, 185, 185);
	color: $dark-75;
}
.device-status-OFFLINE {
	background: #fef4de;
	color: $dark-75;
}
.clickable {
	color: #3699ff;
	cursor: pointer;
}
.clickable:hover {
	color: #0073e9;
}
</style>
